import axios from "axios";
import { mapValues, pick } from "lodash";

export const LS_TOKEN = "clcm-token";

const localStorageKey = "user_store";
export default {
	name: "user",
	namespaced: true,

	state: {
		isAnonymousUser: null,
		region: null,
		foodType: null,
		productName: null,
		productInformation: null,
		user: null,
		token: null,
		isFirstLogin: null,
	},
	getters: {
		getToken() {
			return localStorage.getItem(LS_TOKEN);
		},
	},
	mutations: {
		loadFromLocalStorage(state: TODO) {
			const storedState = localStorage.getItem(localStorageKey);

			if (storedState) {
				Object.assign(state, JSON.parse(storedState));
			}
		},

		removeToken(state: TODO) {
			localStorage.removeItem(LS_TOKEN);
			state.token = null;
		},
		setToken(state: TODO, payload: TODO) {
			localStorage.setItem(LS_TOKEN, payload);
			axios.defaults.headers.common["access-token"] = localStorage.getItem(LS_TOKEN) || "";
			state.token = payload;
		},
		setFirstLogin(state: TODO, payload: TODO) {
			state.isFirstLogin = payload;
		},

		setIsAnonymousUser(state: TODO, payload: TODO) {
			state.isAnonymousUser = payload;
		},
	},
	actions: {
		saveToLocalStorage(state: { state: any }) {
			const currentState = state.state;
			localStorage.setItem(localStorageKey, JSON.stringify(currentState));
		},

		setIsAnonymousUser({ commit, dispatch }: TODO, payload: TODO) {
			console.log("setIsAnonymousUser", payload);
			commit("setIsAnonymousUser", payload);
			dispatch("saveToLocalStorage");
		},

		async login({ commit, dispatch }: TODO, payload: TODO) {
			const { data } = await axios.put("/User/login", payload);
			console.info(data);
			commit("setToken", data.accessToken);
			commit("setFirstLogin", data.firstLogin);
			commit("setIsAnonymousUser", false);
			dispatch("saveToLocalStorage");

			return data;
		},

		async loginAsAnonymous({ commit, dispatch }: TODO) {
			const { data } = await axios.get("/User/anonymous");
			commit("setToken", data.accessToken);
			commit("setIsAnonymousUser", true);
			dispatch("saveToLocalStorage");
			return data;
		},

		async register(context: TODO, payload: TODO) {
			const allowedKeys = [
				"email",
				"salutation",
				"firstName",
				"lastName",
				"companyName",
				"companyType",
				"jobFunction",
				"companyEmployeeCount",
				"countryCode",
				"keepMeUpdated",
			];

			// set null values to NotSet
			payload = mapValues(payload, (value) => {
				if (value === null) {
					return "NotSet";
				}
				return value;
			});

			// filter out unneccessary values
			payload = pick(payload, allowedKeys);

			const data = await axios.post("/User/register", payload);
			console.info(data);
		},

		async logoutLocal({ commit }: TODO) {
			commit("removeToken");
			localStorage.removeItem("user_store");
			localStorage.removeItem("global_key");
			axios.defaults.headers.common["access-token"] = "";
		},

		async logout({ commit }: TODO) {
			if (!axios.defaults.headers.common["access-token"]) {
				return false;
			}
			const { data } = await axios.put("/User/logout");
			commit("removeToken");
			localStorage.removeItem("user_store");
			localStorage.removeItem("global_key");
			axios.defaults.headers.common["access-token"] = "";
			return data;
		},

		async info() {
			if (!axios.defaults.headers.common["access-token"]) {
				return false;
			}
			const { data } = await axios.get("/User/info");
			return data;
		},

		async sendPasswordResetToken(context: TODO, { userEmail }: { userEmail: string }) {
			const { data } = await axios.put("/User/send-password-reset-token", {
				userEmail,
			});
			return data;
		},

		async resetPassword(
			context: TODO,
			{ passwordResetToken, userEmail }: { passwordResetToken: string; userEmail: string },
		) {
			const { data } = await axios.post("/User/reset-password", {
				passwordResetToken,
				userEmail,
			});
			return data;
		},

		async lastAcceptedPolicy(context: TODO, accepted: boolean) {
			const { data } = await axios.put("/User/last-accepted-policy", {
				accepted,
			});
			return data;
		},
	},
	plugins: [
		(store: any) => {
			store.subscribe((mutation: any, _state: any) => {
				if (mutation.type !== "loadFromLocalStorage") {
					store.dispatch("saveToLocalStorage");
				}
			});
		},
	],
};
