export const tooltipOptions = {
	themes: {
		"tooltip-bounds": {
			$extend: "tooltip",
			delay: {
				show: 0,
				hide: 0,
			},
			hideTriggers: ["hover"],
			modifiers: [
				{
					name: "preventOverflow",
					options: {
						mainAxis: true,
						altAxis: true,
						padding: 8,
					},
				},
			],
		},
		input: {
			$extend: "dropdown",
			offset: [0, 8],
			placement: "left",
			triggers: [],
			autoHide: false,
			delay: { show: 0, hide: 0 },
			modifiers: [
				{
					name: "preventOverflow",
					options: {
						mainAxis: true,
						altAxis: true,
						padding: 8,
					},
				},
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top"],
					},
				},
			],
		},
	},
};
