<template>
	<div
		class="flex flex-1 flex-col [--action-bar-height:theme(size.16)] data-[action-bar=shown]:pb-[--action-bar-height]"
		:data-action-bar="route.meta.showFixedActionBar ? 'shown' : 'hidden'"
		:data-compact="$store.state.global.expertMode ? 'enabled' : 'disabled'"
		:data-results="resultsShown ? 'shown' : 'hidden'"
		:date-expert-mode="$store.state.global.expertMode"
	>
		<Header v-show="!route.meta.hideHeader" />
		<RouterView class="flex flex-1 flex-col" />
		<Footer v-show="!route.meta.hideFooter" class="results:hidden" />
	</div>

	<Symbols />
	<Toaster rich-colors class="pointer-events-auto" />
</template>

<script setup lang="ts">
import { onErrorCaptured } from "vue";
import { AxiosError } from "axios";
import { storeToRefs } from "pinia";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { useRoute } from "vue-router";
import { toast } from "vue-sonner";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Symbols from "@/components/svg-symbols.vue";
import { Toaster } from "@/components/ui/sonner";
import { useMiscStore } from "@/store/misc";

const route = useRoute();
const { resultsShown } = storeToRefs(useMiscStore());

onErrorCaptured((error, instance, info) => {
	console.error({ error, instance, info });

	if (error instanceof AxiosError) {
		if (error.response) {
			toast.error(error.response.statusText, { description: error.response.data?.title });
		} else {
			toast.error(error.message);
		}
	} else {
		toast.error(error.message);
	}
});

const { updateServiceWorker } = useRegisterSW({
	immediate: true,
	onNeedRefresh,
	onOfflineReady,
});

function onNeedRefresh() {
	toast.info("New content available", {
		id: "pwa:onNeedRefresh",
		description: "Reload to show the latest content",
		dismissible: false,
		duration: Infinity,
		important: true,
		action: { label: "Reload", onClick: () => updateServiceWorker() },
	});
}

function onOfflineReady() {
	toast.info("App is ready to work offline", { id: "pwa:onOfflineReady" });
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
