import foodTypes from "@/data/food-types.json";
import regions from "@/data/regions.json";

const localStorageKey = "global_key";

type FoodType = {
	name: string;
	slug: string;
	icon: string;
	num: number;
};

type Region = {
	id?: string | null;
	name: string;
	slug: string;
	num: number;
};

export type GlobalState = {
	foodTypes: FoodType[];
	regions: Region[];
	region: Region;
	foodType: FoodType;
	productName: string | null;
	productInformation: string | null;
	ingredients: any;
	ingredientsList: any;
	expertMode: boolean;
};

const globalModule = {
	name: "global",
	namespaced: true,
	state: {
		foodTypes,
		regions,
		region: {
			name: "North-America",
			slug: "NorthAmerica",
			num: 1,
		},
		foodType: {
			name: "Cured meat",
			slug: "CuredMeat",
			icon: "icon_cured-meat",
			num: 0,
		},
		productName: null,
		productInformation: null,
		ingredients: null,
		ingredientsList: null,
		expertMode: false,
	} satisfies GlobalState,
	getters: {
		getRegionIdByName: (state: GlobalState) => (name: string) => {
			if (!state.regions) {
				return null;
			}
			// eslint-disable-next-line no-shadow
			const region = state.regions.find((region) => region.name === name);
			return region?.id;
		},
		getRegion: (state: GlobalState) => state.region,
		getFoodType: (state: GlobalState) => state.foodType,
	},
	mutations: {
		setRegion(state: GlobalState, payload: Region) {
			state.region = payload;
		},
		setRegions(state: GlobalState, payload: Region[]) {
			state.regions = payload;
		},
		setFoodType(state: GlobalState, payload: FoodType) {
			state.foodType = payload;
		},
		setFoodTypes(state: GlobalState, payload: FoodType[]) {
			state.foodTypes = payload;
		},
		setIngredients(state: GlobalState, payload: any) {
			state.ingredients = payload;
		},
		setIngredientsList(state: GlobalState, payload: any) {
			state.ingredientsList = payload;
		},
		setProductName(state: GlobalState, payload: string) {
			state.productName = payload;
		},
		setProductInformation(state: GlobalState, payload: string) {
			state.productInformation = payload;
		},
		setExpertMode(state: GlobalState, payload: boolean) {
			state.expertMode = payload;
		},
		loadFromLocalStorage(state: GlobalState) {
			const storedState = localStorage.getItem(localStorageKey);

			if (storedState) {
				Object.assign(state, JSON.parse(storedState));
			}
		},
	},
	actions: {
		saveToLocalStorage(state: { state: GlobalState }) {
			const currentState = state.state;
			localStorage.setItem(localStorageKey, JSON.stringify(currentState));
		},

		setFoodType({ commit, dispatch }: TODO, payload: FoodType) {
			commit("setFoodType", payload);
			dispatch("saveToLocalStorage");
		},
		setRegion({ commit, dispatch }: TODO, payload: Region) {
			console.info("set region", payload);
			commit("setRegion", payload);
			dispatch("saveToLocalStorage");
		},
		setProductName({ commit, dispatch }: TODO, payload: string) {
			// remove hashes
			commit("setProductName", payload.split("#").join(""));
			dispatch("saveToLocalStorage");
		},
		setProductInformation({ commit, dispatch }: TODO, payload: string) {
			commit("setProductInformation", payload.split("#").join(""));
			dispatch("saveToLocalStorage");
		},
		setExpertMode({ commit, dispatch }: TODO, payload: boolean) {
			commit("setExpertMode", payload);
			dispatch("saveToLocalStorage");
		},
	},
	plugins: [
		(store: any) => {
			store.subscribe((mutation: any, _state: any) => {
				if (mutation.type !== "loadFromLocalStorage") {
					store.dispatch("saveToLocalStorage");
				}
			});
		},
	],
};

export default globalModule;
