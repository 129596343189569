import { createApp } from "vue";
import axios from "axios";
import FloatingVue from "floating-vue";
import { createPinia } from "pinia";
import { clientEnv } from "@/lib/env/client.js";
import "@/lib/polyfills/cryptoRandomUUID";
import { tooltipOptions } from "@/options/tooltip";
import { router } from "@/router";
import { setupSentry } from "@/sentry";
import { LS_TOKEN } from "@/store/modules/user";
import { store } from "@/store/store";
import "@/styles/app.scss";
import { logInfo } from "@/utils/logInfo";
import App from "./App.vue";
import { registerComponents } from "./components";

const pinia = createPinia();

const app = createApp(App);

// vue-router
app.use(router);

// vuex
app.use(store);

// floating-vue
app.use(FloatingVue, tooltipOptions);

// Axios
axios.defaults.baseURL = clientEnv.VITE_CORBION_API_ENDPOINT;
// Set authorization headers based on localStorage
axios.defaults.headers.common["access-token"] = localStorage.getItem(LS_TOKEN) || "";

// Components
registerComponents(app);

// Pinia
app.use(pinia);

setupSentry({ app, router });

router.isReady().then(() => {
	// Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
	app.mount("#app");
});

logInfo();
