<template>
	<header
		v-bind="$attrs"
		class="z-50 flex min-w-0 bg-gradient-to-r from-brand-secondary-darker to-brand-secondary md:h-28"
	>
		<router-link
			class="min-h-16 w-1/12 min-w-16 max-w-56 bg-white p-2 md:w-1/6 md:p-6"
			:to="{
				name: isAuthenticated
					? router.currentRoute.value.name === 'Calculate'
						? 'Calculate'
						: 'Configuration'
					: 'Authentication',
			}"
		>
			<sb-logo />
		</router-link>

		<div
			class="flex min-w-0 flex-1 items-center justify-between gap-4 bg-[url(/img/top_bg.png)] bg-right bg-no-repeat px-8 py-4 text-white"
		>
			<div class="flex min-w-0 flex-col items-start">
				<h1
					data-unstyled
					class="w-full min-w-0 truncate text-2xl font-bold lg:text-3xl xl:text-4xl"
				>
					Corbion
					<em>Listeria</em> Control Model
				</h1>
				<small class="hidden w-full min-w-0 truncate text-base text-white/40 md:block">
					Biobased ingredients for shelf life, freshness and food safety
				</small>
			</div>

			<div class="hidden min-w-0 flex-1 flex-col items-end gap-2 lg:flex">
				<Button
					v-if="isAuthenticated"
					variant="link"
					size="auto"
					class="max-w-full justify-end gap-2 text-base text-white"
					@click="signOut"
				>
					<LogOut class="size-3 min-w-3 rotate-180" />
					<span class="truncate"> Log out </span>
				</Button>
				<Button
					v-else
					:as="RouterLink"
					:to="{ name: 'Authentication' }"
					variant="link"
					size="auto"
					class="max-w-full gap-2 truncate text-base text-white"
				>
					<LogIn class="size-3 min-w-3" />
					<span class="truncate"> Log in </span>
				</Button>

				<div class="flex flex-wrap items-center justify-end gap-x-3 gap-y-1">
					<Button
						:as="RouterLink"
						:to="{ name: 'FAQ' }"
						variant="link"
						size="auto"
						class="text-base text-white"
					>
						FAQ
					</Button>
					<Button
						as="a"
						href="https://www.corbion.com/Markets/Food"
						variant="link"
						size="auto"
						class="text-base text-white"
					>
						About us
					</Button>
					<Button
						as="a"
						href="mailto:lcm@corbion.com?subject=LCM"
						variant="link"
						size="auto"
						class="text-base text-white"
					>
						Contact
					</Button>
				</div>
			</div>

			<Sheet>
				<SheetTrigger as-child>
					<Button size="icon" class="flex lg:hidden" aria-label="Open menu" variant="naked">
						<Menu class="size-6" />
					</Button>
				</SheetTrigger>
				<SheetContent
					side="top"
					class="flex flex-col bg-gradient-to-r from-brand-secondary-darker to-brand-secondary"
				>
					<div
						class="flex flex-col divide-y divide-solid divide-brand-gray/30 *:rounded-none *:border-x-0 *:py-4 focus-visible:*:rounded-lg"
					>
						<SheetClose as-child>
							<Button
								v-if="isAuthenticated"
								variant="link"
								size="auto"
								class="max-w-full gap-2 text-2xl text-white"
								@click="signOut"
							>
								<LogOut class="size-3 min-w-3 rotate-180" />
								<span class="truncate"> Log out </span>
							</Button>
							<Button
								v-else
								:as="RouterLink"
								:to="{ name: 'Authentication' }"
								variant="link"
								size="auto"
								class="max-w-full gap-2 text-2xl text-white"
							>
								<LogIn class="size-3 min-w-3" />
								<span class="truncate"> Log in </span>
							</Button>
						</SheetClose>

						<SheetClose as-child>
							<Button
								:as="RouterLink"
								:to="{ name: 'FAQ' }"
								variant="link"
								size="auto"
								class="text-2xl text-white"
							>
								FAQ
							</Button>
						</SheetClose>

						<SheetClose as-child>
							<Button
								as="a"
								href="https://www.corbion.com/Markets/Food"
								variant="link"
								size="auto"
								class="text-2xl text-white"
							>
								About us
							</Button>
						</SheetClose>

						<SheetClose as-child>
							<Button
								as="a"
								href="mailto:lcm@corbion.com?subject=LCM"
								variant="link"
								size="auto"
								class="text-2xl text-white"
							>
								Contact
							</Button>
						</SheetClose>
					</div>
				</SheetContent>
			</Sheet>
		</div>
	</header>
</template>

<script setup lang="ts">
import { LogIn, LogOut, Menu } from "lucide-vue-next";
import { useRouter } from "vue-router";
import { RouterLink } from "vue-router";
import SbLogo from "@/components/Logo.vue";
import { Button } from "@/components/ui/button";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useIsAuthenticated } from "@/lib/useIsAuthenticated";
import { store } from "@/store/store";

const isAuthenticated = useIsAuthenticated();
const router = useRouter();

async function signOut() {
	await store.dispatch("user/logout");
	router.push({ name: "Authentication" });
}
</script>
